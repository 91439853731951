import axios from 'axios';
import api from '../ApiConfig';

export type Token = {
  id: number;
  name: string;
  projectId: number;
  createdAt: number;
  updatedAt: number;
  tokenString: string;
};

export type GetTokensResponse = {
  data: Token[];
};

export type CreateTokenResponse = {
  data: Token;
}

export type RequestError = {
  message: string;
  code: number | null;
}

export async function GetTokens(success: (response: GetTokensResponse) => void, failure: (response: RequestError) => void) {
  try {
    const { data, status } = await api.get<GetTokensResponse>('/tokens');

    console.log(JSON.stringify(data, null, 4));
    console.log('response status is: ', status);
    success(data);

  } catch (error) {
    let errorMessage = 'An unexpected error ocurred';
    let code = null;

    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message);
      errorMessage = error.message;
      if (error.response) {
        code = error.response.status;
      }
    }

    const response = {
      message: errorMessage,
      code: code
    }

    failure(response);
  }
}

export async function CreateToken(tokenData: any, success: (response: CreateTokenResponse) => void, failure: (response: RequestError) => void) {
  try {
    const { data, status } = await api.post<CreateTokenResponse>('/tokens', tokenData);
    console.log(JSON.stringify(data, null, 4));
    console.log('response status is: ', status);
    success(data);
  } catch (error) {
    let errorMessage = 'An unexpected error occurred';
    let code = null;
    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message);
      errorMessage = error.message;
      if (error.response) {
        code = error.response.status;
      }
    }
    const response = {
      message: errorMessage,
      code: code
    }
    failure(response);
  }
}
