import { Formik } from "formik";
import React from "react";
import { Form, Container, Button } from "react-bootstrap";
import { CreateProject, CreateProjectResponse, RequestError } from "../Integrations/Backend/Projects";

const FormInput = ({ formSubmit }: { formSubmit: any }) => {
  return (
    <Formik
      initialValues={{ name: "" }}
      onSubmit={formSubmit}
    >
      {( {values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="project-name">Project Name</Form.Label>
            <Form.Control
              id="project-name"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              placeholder="Any custom identifier for you to recognize this project"
            />
          </Form.Group>

          <Button variant="primary" type="submit" disabled={isSubmitting}>Create project</Button>
        </Form>
      )}
    </Formik>
  )
}

interface IProjectForm {
  createSuccess: (response: CreateProjectResponse) => void,
  createFailure: (response: RequestError) => void
}

function ProjectForm({createSuccess , createFailure}: IProjectForm) {
  const submitCreateProject = (values: any, { setSubmitting, resetForm }: {setSubmitting: any, resetForm: any}) => {
    setSubmitting(true);
    console.log('Sending request');
    CreateProject(values, createSuccess, createFailure);
    setSubmitting(false);
  }

  return (
    <Container fluid>
      <FormInput formSubmit={submitCreateProject}/>
    </Container>
  );
}

export default ProjectForm;
