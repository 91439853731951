import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useCognitoAuth } from "../Hooks/CognitoAuthProvider";
import { Logout } from "../Integrations/Backend/Logout";

interface IMainNavBarProps {
  pagesLeft?: any[],
  pagesRight?: any[]
}

function MainNavBar({ pagesLeft, pagesRight }: IMainNavBarProps) {
  const { user } = useCognitoAuth();

  const signout = () => {
    Logout();
  }

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container fluid>
        <Navbar.Brand href="/">LOGO</Navbar.Brand>
        <Navbar.Toggle aria-controls="main-navbar" />
        <Navbar.Collapse id="main-navbar">
          <Nav className="me-auto">
            {pagesLeft?.map((page, idx: number) => {
              return (
                <Nav.Link key={idx} href={page.path}>{page.label}</Nav.Link>
              );
            })}
          </Nav>

          <Nav>
            {pagesRight?.map((page, idx: number) => {
              return (
                <Nav.Link key={idx} href={page.path}>{page.label}</Nav.Link>
              );
            })}
            {!!user && (
              <Nav.Link key="logout" onClick={signout}>Logout</Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainNavBar;
