import { Navigate, Outlet } from "react-router-dom";
import { useCognitoAuth } from "../Hooks/CognitoAuthProvider";
import MainNavBar from "../Navigation/MainNavBar";

export const LoggedOutLayout = () => {
  const { user } = useCognitoAuth();

  if (user) {
    return <Navigate to="/dashboard/projects" replace />;
  }

  return (
    <div>
      <MainNavBar
        pagesLeft={[{ path: "#what", label: "What" }, { path: "#pricing", label: "Pricing" }]}
        pagesRight={[{ path: "/signin", label: "Sign in" }, { path: "/signup", label: "Sign up" }]}
      />
      <Outlet />
    </div>
  );
};
