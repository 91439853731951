import React, { Dispatch, SetStateAction } from "react";
import Alert from 'react-bootstrap/Alert';

interface DismissibleAlertProps {
  showAlert: boolean,
  variant: string,
  heading: string,
  content?: string,
  onClose?: Dispatch<SetStateAction<boolean>>
}

function DismissibleAlert({ showAlert, variant, heading, content, onClose }: DismissibleAlertProps) {
  if (showAlert) {
    return (
      <Alert variant={variant} onClose={onClose} dismissible>
        <Alert.Heading>{heading}</Alert.Heading>
        <p>{content}</p>
      </Alert>
    )
  }
  return <></>
}

export default DismissibleAlert;
