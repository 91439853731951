import { Modal } from "react-bootstrap";

interface ICenteredModal {
  show: boolean,
  title: string,
  body: React.ReactNode,
  onClose: () => void
}

function CenteredModal({ show, title, body, onClose }: ICenteredModal ) {
  return (
    <Modal
      onHide={onClose}
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {body}
      </Modal.Body>
    </Modal>
  )
}

export default CenteredModal;
