import React from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Formik } from "formik";
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
import { useCognitoAuth } from "../Hooks/CognitoAuthProvider";
import { Login, LoginDetails } from "../Integrations/Backend/Login";

function Signin() {
  const navigate = useNavigate();
  const { setUser } = useCognitoAuth();

  const signinSchemaValidation = Yup.object().shape({
    email: Yup.string()
    .email("*Must be a valid email address")
    .required("*Email is required"),
    password: Yup.string()
    .required("*Password is required")
  });

  const signin = (values: any, {setSubmitting, resetForm}: {setSubmitting: any, resetForm: any}) => {
    setSubmitting(true);
    Login(values.email, values.password).then((loginDetails: LoginDetails) => {
      setUser(loginDetails.userDisplayName) // TODO: Update backend to send info about the user so we can use it to be displayed
      navigate("/dashboard/projects", { replace: true });
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      setSubmitting(false);
    });
  };

  return(
    <div id="signin-page">
      <div className="container">
        <h1>Sign in</h1>

        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={signinSchemaValidation}
          onSubmit={signin}
        >
          {( {values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  className={touched.email && errors.email ? "error" : ""}
                />
                {touched.email && errors.email ? (
                  <div className="error-message">{errors.email}</div>
                ): null}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  className={touched.password && errors.password ? "error" : ""}
                />
                {touched.password && errors.password ? (
                  <div className="error-message">{errors.password}</div>
                ): null}
              </Form.Group>

              <Button variant="primary" type="submit" disabled={isSubmitting}>Sign in</Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Signin;
