import { unauthenticatedApi } from '../ApiConfig';

export type RequestError = {
  message: string;
  code: number | null;
}

export const SignupApi = (email: string, password: string) => {
  return new Promise((resolve, reject) => {
    const signupPayload = {
      email,
      password
    }
    unauthenticatedApi.post('/signup', signupPayload)
    .then((data) => {
      resolve(data);
    })
    .catch((error) => {
      reject(error.response);
    })
  });
}
