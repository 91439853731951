import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Signin from './Pages/Signin';
import Signup from './Pages/Signup';
import ConfirmAccount from './Pages/ConfirmAccount';
import Home from './Pages/Home';
import { LoggedOutLayout } from './Pages/LoggedOutLayout';
import { ProtectedLayout } from './Pages/ProtectedLayout';
import Projects from './Pages/Projects';
import Settings from './Pages/Settings';
import NotFound from './Pages/NotFound';

function App() {
  return (
    <Routes>
      <Route element={<LoggedOutLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/confirm-account" element={<ConfirmAccount />} />
      </Route>

      <Route path="/dashboard" element={<ProtectedLayout />}>
        <Route path="projects" element={<Projects />} />
        <Route path="settings" element={<Settings />} />
      </Route>

      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}

export default App;
