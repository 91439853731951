import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import "./NotFound.css";

function NotFound() {
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate("/", { replace: true });
  }

  return (
    <div id="not-found-section" className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
      <h1>404</h1>
      <h2>UH OH! You're lost.</h2>
      <p>The page you're looking for does not exist. How you got here is a mystery but you can click the button below to go back to the homepage</p>
      <Button size="lg" variant="outline-light" onClick={navigateHome}>Back to Home</Button>
    </div>
  );
}

export default NotFound;
