import { createContext, useContext, useState } from "react";
import { getUsername } from "../Integrations/Cognito/Auth";

interface IAuthContext {
  user: any;
  setUser: any;
}

const AuthContext = createContext({} as IAuthContext);

export const CognitoAuthProvider = ({ children }: { children: any } ) => {
  const [user, setUser] = useState(getUsername());

  const value = {
    user,
    setUser
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useCognitoAuth = () => {
  return useContext(AuthContext);
}
