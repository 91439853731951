import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import CenteredModal from "../Components/CenteredModal";
import ProjectForm from "../Components/ProjectForm";
import { Project, GetProjects, GetProjectsResponse, CreateProjectResponse, RequestError } from "../Integrations/Backend/Projects";

function Projects() {
  const projectsSuccessFetch = (response: GetProjectsResponse) => {
    setProjects(response.data);
  }

  const projectsFailureFetch = (response: RequestError) => {
    setError(response.message);
  }

  const createSuccess = (response: CreateProjectResponse) => {
    console.log(response);
    setModalShow(false);
  }

  const createFailure = (response: RequestError) => {
    console.log(response);
    setModalShow(false);
    setError(response.message);
  }

  const [projects, setProjects] = useState<Project[]>([]);
  const [error, setError] = useState('');
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    GetProjects(projectsSuccessFetch, projectsFailureFetch);
  }, [modalShow]);

  const ErrorDisplay = () => {
    return <h2>{error}</h2>
  }
  return (
    <>
      <CenteredModal
        show={modalShow}
        onClose={() => setModalShow(false)}
        title="Create new project"
        body={<ProjectForm createSuccess={createSuccess} createFailure={createFailure}/>}
      />

      <Container fluid>
        <h1>Projects</h1>
        { error?.length > 0 && ErrorDisplay() }

        <Row>
          <Col xs={12} md={12}>
            <Button className="float-md-end" variant="primary" onClick={() => setModalShow(true)}>Create Project</Button>
          </Col>
        </Row>

        <Table striped bordered hover variant="dark">
          <thead>
            <tr>
              <th>Project Id</th>
              <th>Project Name</th>
              <th>Created At</th>
              <th>Updated At</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project) => {
              return <tr key={project.id}>
                      <td>{project.id}</td>
                      <td>{project.name}</td>
                      <td>{project.createdAt}</td>
                      <td>{project.updatedAt}</td>
                    </tr>
            })}

          </tbody>
        </Table>
      </Container>
    </>
  );
}

export default Projects;
