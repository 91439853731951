import { unauthenticatedApi } from '../ApiConfig';

export type RequestError = {
  message: string;
  code: number | null;
}

export const ConfirmAccountApi = (email: string, confirmationCode: string) => {
  return new Promise((resolve, reject) => {
    const confirmAccountPayload = {
      email,
      confirmationCode
    }
    unauthenticatedApi.post('/confirm-account', confirmAccountPayload)
    .then((data) => {
      resolve(data);
    })
    .catch((error) => {
      reject(error.response);
    })
  });
}
