import { unauthenticatedApi } from '../ApiConfig';

export type LoginUser = {
  email: string;
  password: string;
};

export type LoginDetails = {
  accessToken: string;
  idToken: string;
  refreshToken: string;
  email: string;
  userDisplayName: string;
};

export type RequestError = {
  message: string;
  code: number | null;
}

export const Login = (email: string, password: string) => {
  return new Promise<LoginDetails>((resolve, reject) => {
    unauthenticatedApi.post('/login', { email: email, password: password })
    .then((data) => {
      const authResult = data.data.AuthenticationResult
      const loginDetails = {
        accessToken: authResult.AccessToken,
        idToken: authResult.IdToken,
        refreshToken: authResult.RefreshToken,
        email: email,
        userDisplayName: email
      }
      window.localStorage.setItem("Email", JSON.stringify(email));
      window.localStorage.setItem("AuthDetails", JSON.stringify(loginDetails));
      resolve(loginDetails);
    })
    .catch((error) => {
      console.log(error);
      window.localStorage.removeItem("Email");
      window.localStorage.removeItem("AuthDetails");
      reject(error);
    })
  });
}
