import axios from 'axios';
import { Logout } from './Backend/Logout';

import { getUserAccessToken } from './Cognito/Auth';

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://api.corgi.bltz.pw'

const api = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

api.interceptors.request.use(
  async config => {
    config.headers = config.headers ?? {};
    const jwtToken = await getUserAccessToken();
    config.headers['Authentication'] = String(jwtToken);
    return config;
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => new Promise((resolve, reject) => {
    resolve(response);
  }),
  error => {
    if(!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    if(error.response.status === 401) {
      Logout();
    }
    else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

export const unauthenticatedApi = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

export default api;
