import axios from 'axios';
import api from '../ApiConfig';

export type Project = {
  id: number;
  name: string;
  createdAt: number;
  updatedAt: number;
};

export type GetProjectsResponse = {
  data: Project[];
};

export type CreateProjectResponse = {
  data: Project;
}

export type RequestError = {
  message: string;
  code: number | null;
}

export async function GetProjects(success: (response: GetProjectsResponse) => void, failure: (response: RequestError) => void) {
  try {
    const { data, status } = await api.get<GetProjectsResponse>('/projects');

    console.log(JSON.stringify(data, null, 4));
    console.log('response status is: ', status);
    success(data);

  } catch (error) {
    let errorMessage = 'An unexpected error ocurred';
    let code = null;

    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message);
      errorMessage = error.message;
      if (error.response) {
        code = error.response.status;
      }
    }

    const response = {
      message: errorMessage,
      code: code
    }

    failure(response);
  }
}

// export async function GetProjectById(id:number): Promise<Project> {
//   try {
//     const { data, status } = await api.get<Project>(`/projects/${id}`);

//     console.log(JSON.stringify(data, null, 4));
//     console.log('response status is: ', status);
//     return data;
    // setData(data);
    // setError(null);
  // } catch (error) {
    // return Promise.reject('An unexpected error ocurred')
    // return { name: '', id: 1, pr };
    // let errorMessage = 'An unexpected error ocurred';

    // if (axios.isAxiosError(error)) {
    //   console.log('error message: ', error.message);
    //   errorMessage = error.message;
    // } else {
    //   console.log('unexpected error: ', error);
    // };
    // setData([]);
    // setError(errorMessage);
//   }
// }

export async function CreateProject(projectData: any, success: (response: CreateProjectResponse) => void, failure: (response: RequestError) => void) {
  try {
    const { data, status } = await api.post<CreateProjectResponse>('/projects', projectData);
    console.log(JSON.stringify(data, null, 4));
    console.log('response status is: ', status);
    success(data);
  } catch (error) {
    let errorMessage = 'An unexpected error occurred';
    let code = null;
    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message);
      errorMessage = error.message;
      if (error.response) {
        code = error.response.status;
      }
    }
    const response = {
      message: errorMessage,
      code: code
    }
    failure(response);
  }
}
