import { Navigate, Outlet } from "react-router-dom";
import { useCognitoAuth } from "../Hooks/CognitoAuthProvider";
import MainNavBar from "../Navigation/MainNavBar";

export const ProtectedLayout = () => {
  const { user } = useCognitoAuth();

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <MainNavBar
        pagesLeft={[{ path: "projects", label: "Projects" }, { path: "settings", label: "Settings" }]}
      />
      <Outlet />
    </div>
  );
};
