import React from "react";

function Home() {
  return (
    <div className="home-page">
      <h1>Home</h1>
      <h2 id="what">What</h2>
      <h2 id="pricing">Pricing</h2>
    </div>
  );
}

export default Home;
