import { CognitoUser } from "amazon-cognito-identity-js";
import UserPool from "./UserPool";

export const getUserAccessToken = async () => {
  return new Promise<string>((resolve, reject) => {
    const authDetails = window.localStorage.getItem("AuthDetails");
    if (authDetails != null) {
      const parsedDetails = JSON.parse(authDetails)
      if (parsedDetails != null) {
        return resolve(parsedDetails["accessToken"]);
      }
    }
    reject('Not authenticated')
  });
};

export const getUsername = (): String => {
  return window.localStorage.getItem("Email") || "";
}

export const cognitoConfirmAccount = async (email: string, confirmationCode: string) => {
  const username = email.replace(/@/, "-at-");

  const user = new CognitoUser({
    Username: username,
    Pool: UserPool
  });

  return new Promise((resolve, reject) => {
    const callback = (err: any, result: any) => {
      if (err) {
        reject(err);
        return;
      }

      resolve(result);
    }
    user.confirmRegistration(confirmationCode, true, callback);
  })
}
