import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Form, Container, Button, InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { RequestError } from "../Integrations/Backend/Login";
import { GetProjects, GetProjectsResponse, Project } from "../Integrations/Backend/Projects";
import { CreateToken, CreateTokenResponse } from "../Integrations/Backend/Tokens";

const DatePickerField = ({ name, value, onChange }: {name: string, value: Date | null, onChange: any}) => {
  return (
    <DatePicker
      selected={(value && new Date(value)) || null}
      onChange={val => {
        onChange(name, val);
      }}
      isClearable
    />
  );
};

interface IFormInputValues {
  name: string,
  expiryDate: Date | null,
  projectId?: number
}

const FormInput = ({ formSubmit, userProjects }: { formSubmit: any, userProjects: Project[] }) => {
  const initialValues: IFormInputValues = {
    name: "",
    expiryDate: null,
    projectId: undefined
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={formSubmit}
    >
      {( {values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="pat-identifier">Custom identifier</Form.Label>
            <Form.Control
              id="pat-identifier"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              placeholder="Any custom identifier for you to recognize this token"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label htmlFor="project-selector">Project</Form.Label>
            <Form.Control
              as="select"
              id="project-selector"
              placeholder="Select project"
              onChange={e => {
                values.projectId = parseInt(e.currentTarget.value, 10);
              }}
              value={values.projectId}
              name="projectId"
            >
              <option>Please select the project you want to create the token for</option>
              {userProjects.map((project) => {
                return <option value={project.id}>{project.name}</option>
              })}
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label htmlFor="expiry-date">Expires at</Form.Label>
            <DatePickerField
              name="expiryDate"
              value={values.expiryDate}
              onChange={setFieldValue}
            />
          </Form.Group>

          <Button variant="primary" type="submit" disabled={isSubmitting}>Create token</Button>
        </Form>
      )}
    </Formik>
  )
}

const FormOutput = ({ token }: { token: string }) => {
  const [copyIcon, setCopyIcon] = useState("bi bi-clipboard");

  const copyToClipboard = () => {
    navigator.clipboard.writeText(token);
    setCopyIcon("bi bi-clipboard-check")
  }

  return (
    <>
      <p>Please copy the token and store it in a safe place. Once closed, this token is not recoverable</p>
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">
          <i className={copyIcon} onClick={copyToClipboard}></i>
        </InputGroup.Text>
        <Form.Control type="text" defaultValue={token} readOnly />
      </InputGroup>
    </>
  )
}

function TokenForm() {
  const [createdToken, setCreatedToken] = useState<string>();
  const [userProjects, setUserProjects] = useState<Project[]>([]);

  const createSuccess = (response: CreateTokenResponse) => {
    setCreatedToken(response.data.tokenString);
  }

  const createFailure = (response: RequestError) => {
    console.log(response);
  }

  const submitCreateToken = (values: any, { setSubmitting, resetForm }: {setSubmitting: any, resetForm: any}) => {
    setSubmitting(true);
    CreateToken(values, createSuccess, createFailure);
    setSubmitting(false);
  }

  const projectSuccessFetch = (response: GetProjectsResponse) => {
    setUserProjects(response.data);
  }

  const projectFailureFetch = (response: RequestError) => {
    console.log("Failed to load projects", response.message);
  }

  useEffect(() => {
    GetProjects(projectSuccessFetch, projectFailureFetch)
  }, []);

  return (
    <Container fluid>
      { !createdToken && <FormInput formSubmit={submitCreateToken} userProjects={userProjects} />}
      { createdToken && <FormOutput token={createdToken} /> }
    </Container>
  );
}

export default TokenForm;
