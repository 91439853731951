import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import CenteredModal from "../Components/CenteredModal";
import TokenForm from "../Components/TokenForm";
import { GetTokens, GetTokensResponse, Token, RequestError } from "../Integrations/Backend/Tokens";

function Settings() {
  const [modalShow, setModalShow] = useState(false);
  const [tokens, setTokens] = useState<Token[]>([]);
  const [, setError] = useState('');

  const tokensSuccessFetch = (response: GetTokensResponse) => {
    setTokens(response.data);
  }

  const tokensFailureFetch = (response: RequestError) => {
    setError(response.message);
  }

  useEffect(() => {
    GetTokens(tokensSuccessFetch, tokensFailureFetch);
  }, []);

  return (
    <>
      <CenteredModal
        show={modalShow}
        onClose={() => setModalShow(false)}
        title="Create new app token"
        body={<TokenForm/>}
      />

      <Container fluid>
        <h1>Settings</h1>
        <Row>
          <Col xs={12} md={10}>
            <h2>Project Access Tokens</h2>
          </Col>
          <Col xs={12} md={2}>
            <Button className="float-md-end" variant="primary" onClick={() => setModalShow(true)}>Add Token</Button>
          </Col>
        </Row>
        <Table striped bordered hover variant="dark">
          <thead>
            <tr>
              <th>Reference</th>
              <th>Token String</th>
              <th>Project Id</th>
              <th>Created At</th>
              <th>Updated At</th>
            </tr>
          </thead>
          <tbody>
            {tokens.map((token) => {
              return <tr key={token.id}>
                      <td>{token.name}</td>
                      <td>{token.tokenString}</td>
                      <td>{token.projectId}</td>
                      <td>{token.createdAt}</td>
                      <td>{token.updatedAt}</td>
                    </tr>
            })}

          </tbody>
        </Table>
      </Container>
    </>
  );
}

export default Settings;
